import classNames from "classnames";
import { Text } from "components/Typography";
import { komiConsumerUrl } from "services/DomainService";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useGlobalState } from "utils/container";

const Row = dynamic(() => import("antd/lib/grid/row"));
const Col = dynamic(() => import("antd/lib/grid/col"));

const ACCEPT_COOKIES = "ACCEPT_COOKIES";

interface AcceptCookiesProps {
  isWebView?: boolean;
}
const AcceptCookies = ({ isWebView }: AcceptCookiesProps) => {
  const [isAccept, acceptCookies] = useState(true);
  const stateCtx: any = useGlobalState();

  useEffect(() => {
    acceptCookies(Cookies.get(ACCEPT_COOKIES) === "true");
  }, [acceptCookies]);

  const handleAcceptCookies = useCallback(() => {
    if (window.location.host.includes("localhost")) {
      Cookies.set(ACCEPT_COOKIES, "true", { expires: 999999 });
    } else {
      document.cookie = `${ACCEPT_COOKIES}=true; Path=/; Expires=Thu, 01 Jan 9999 00:00:01 GMT`;
    }
    acceptCookies(true);
  }, []);
  const renderContent = useCallback(() => {
    return !isAccept && stateCtx?.visibleCookies ? (
      <>
        <div className={classNames("accept-all-cookies", isWebView && "webview")}>
          <Row align="middle">
            <Col md={20} xs={24}>
              <Text
                className={isMobile ? "" : "d--block"}
                preset={isMobile ? "regular14" : "regular16"}
              >{`We use cookies to optimize and enhance your browsing experience. `}</Text>
              <Text className={isMobile ? "" : "d--block"} preset={isMobile ? "regular14" : "regular16"}>
                <span>{`Click `}</span>
                <a href={`${komiConsumerUrl}/privacy-policy`} target="_blank" rel="noreferrer">
                  here
                </a>
                <span>{` for further details.`}</span>
              </Text>
            </Col>
            <Col md={4} xs={24}>
              <div className="accept-cookies-button">
                <Text preset="bold18" className="cursor-pointer" onClick={handleAcceptCookies}>
                  ACCEPT
                </Text>
              </div>
            </Col>
          </Row>
        </div>
      </>
    ) : (
      <></>
    );
  }, [handleAcceptCookies, isAccept, isWebView, stateCtx?.visibleCookies]);

  return <>{renderContent()}</>;
};

export default AcceptCookies;
